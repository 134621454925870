import React from "react";
import PropTypes from "prop-types";
import URL from "url";
import axios from "axios";
import lodash from "lodash";

import { withStyles } from '@material-ui/core/styles';
import defaultMetadata from "./../defaultMetadata";

import classnames from "classnames";
import Section from "./Section";

 const styles = (theme) => { return {
   boxShadowcontent: {
     background: "#fff",
     boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.1)",
     borderRadius: 3,
   },

   textCaptionSmall: {
    fontWeight: 700,
    fontSize: 10,
    textTransform: "uppercase",
    color: "#b6b9bb",
  },

  textCaption: {
    fontWeight: 600,
    fontSize: 12,
    color: "#707274",
  },

  linkText: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },

  Pullup: {
    position: "relative",
    left: 0,
    right: 0,
    top: 0,
    height: 150,
    backgroundColor: "#3a3f42",
  },
}};

class Announcements extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      announcements: []
    };
  }

  componentDidMount(){
    const {metadata: {network_base_url}} = this.props;

    axios.get(URL.resolve(network_base_url, "/api/announcements.json")).then((response) => {
      this.setState({announcements: response.data.data});
    });
  }

  renderSingleAnnouncement = (announcement, key) => {
    const {classes} = this.props;
    const{
      content: announcement_content,
      contributor: {profile_title: announcer_profile_title},
      contributor: {name: announcer_name},
      contributor: {url: contributor_url},
    } = announcement;
    var contributor_currently_at = null;
    var contributor_current_city = null;

    if(announcement.contributor.currently_at) contributor_currently_at = announcement.contributor.currently_at.name
    if(announcement.contributor.current_city) contributor_current_city = announcement.contributor.current_city.name

    var announcement_course_name = null;
    var announcement_batch_small = null;
    var announcer_designation = null;

    if("course" in announcement){
      announcement_course_name = announcement.course.name;
      announcement_batch_small = announcement.contributor.batch_small;
    }
    if("designation" in announcement.contributor){
      announcer_designation = announcement.contributor.designation;
    }
    return(
      <div style = {{border: "1px solid rgb(241, 240, 240)", marginTop: 8, padding: "12px 16px", fontWeight: 500,}}>
        <div style={{padding: "4px 16px"}}>
          <a href={contributor_url} className = {classes.linkText}>
            <span style = {{fontWeight: 700, fontSize: 14, color: "#3a3f42"}}>
              {announcer_name}
            </span>
          </a>
          <span>
          {announcement_course_name ? (
              <span className = {classes.textCaptionSmall} style = {{paddingLeft: 8}}>
                {announcement_course_name} {announcement_batch_small}
              </span>
            ):(null)
          }
            {announcer_designation ? (
              <span className = {classes.textCaptionSmall} style = {{paddingLeft: 8}}>
                {announcement.contributor.designation}
              </span>
            ):(null)
            }
          </span>
          <div className={classes.textCaption}>
            <div style={{paddingTop: 10, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow:"hidden", width: "100%"}}>
            {announcer_profile_title + " at " + contributor_currently_at + ", " + contributor_current_city}
            </div>
          </div>
          <div style={{clear: "both"}}></div>
        </div>
        <div style={{padding: "8px 16px", color: "#3a3f42", fontSize: 14, fontWeight: 600, whiteSpace: "pre-wrap", overflow:"hidden"}}>
          {announcement_content}
        </div>
      </div>
    );
  }
  renderAnnouncements = () => {
    const {metadata:{network_base_url}} = this.props;
    const {classes} = this.props;
    let announcementsComponents = null;
    if(!lodash.isEmpty(this.state.announcements)){
      announcementsComponents = this.state.announcements.map(this.renderSingleAnnouncement);
    }
    return(
      <>
        {announcementsComponents}
      </>
    )
  }
  render(){
    const {classes, metadata: {network_base_url, announcements_enabled}, scrollHeight} = this.props;
    if(!announcements_enabled) return null;
    const viewAllUrl = URL.resolve(network_base_url, "/contributions/sneak_peek");
    const scrollStyle = {height: this.props.scrollHeight || 934, overflow: "auto"}
    return(
      <>
        <Section sectionHeading="Announcements"
          style={this.props.style}
          action={{name: "View All", url: viewAllUrl}}
          >
          <div style={scrollStyle}>
            {this.renderAnnouncements()}
          </div>
        </Section>
      </>
    );
  }

}
export default withStyles(styles, { withTheme: true })(Announcements);
