import React from "react";
import PropTypes from "prop-types";
import URL from "url";
import axios from "axios";
import lodash from "lodash";

import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Hidden from '@material-ui/core/Hidden';
import { FaFacebookF, FaYoutube, FaTwitter, FaInstagram } from 'react-icons/fa';

import { withStyles } from '@material-ui/core/styles';
import Footer from "./../../base/components/Footer"
import {
  Header, Banners, TwitterFeed, Section,
  AlumniNews, AlumniMap, DistinguishedAlumni, UpcomingEvents, SearchBarComponent,
  RecentMemories, NetworkAndLogin, ContentContainer, PullupContent, NewsLetter, Announcements, GetInTouch
} from "./../../base/components/CommonComponents";
//import metadata from "./metadata";
import defaultMetadata from "./../../base/defaultMetadata";

const styles = (theme) => ({
  mainComponentsOuterContainer:{
    maxWidth: 1280,
    margin: 'auto',
    [theme.breakpoints.down(1281)]: {
      maxWidth: 1120,
      padding: '0px 24px'
    }
  },
  footerContainer: {
    maxWidth: 1156,
    margin: 'auto',
    padding: '0 32px',
    color: defaultMetadata.theme.colors.battleshipGrey,
  },
  footerDivider: {
    backgroundColor: defaultMetadata.theme.colors.coolGrey,
    height: 1,
  },
  footerLinkSaperator: {
    display: "inline-block",
    marginLeft: 8,
    marginRight: 8,
  },
  footerToggleAlign: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
      textAlign: 'right',
    }
  },
  Pullup: {
    position: "relative",
    height: 150,
    backgroundColor: "#3a3f42",
    zIndex: "1",
  },
});

class Root extends React.Component {

  constructor(props){
    super(props);
  }

  renderFooter = () => {
    const {classes} = this.props;
    const {metadata:{logo:{url: logo_url}, twitter_link, facebook_link, instagram_link, youtube_link}} = this.props;
    const {metadata} = this.props;
    return (
      <div style={{lineHeight: 1.5, marginTop: 40}}>
        <div className={classes.footerDivider} style={{height: 2}}></div>
        <div className={classes.footerDivider} style={{marginTop: 2, marginBottom: 24}}></div>
        <div className={classes.footerContainer}>
          <Grid container justify="center">
            <Footer metadata={metadata} />
          </Grid>
        </div>
      </div>
    );
  };

  renderVideos = () => {
    const {metadata:{videos}} = this.props;

    let videoComponents = videos.map((video, index) => {
      return (
        <Grid item sm={12} md={6} style={{width: "100%"}} key={index}>
          <Section sectionHeading={video.title}>
            <iframe width="100%"
                    // style={{minHeight: 225}}
                    height="225px"
                    src={video.url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={video.title}
            ></iframe>
          </Section>
        </Grid>
      );
    });

    return (
      <div style={{marginTop: 24}}>
        <ContentContainer>
          <Grid container direction="row" spacing={24} justify="center" alignItems="center">
            {videoComponents}
          </Grid>
        </ContentContainer>
      </div>
    );
  }

  renderTiles = () => {
    const {metadata:{tiles}} = this.props;
    if(tiles.length == 0) return null;
    let tileComponents = tiles.map((tile, index) => {
      return (
        <Grid item sm={12} md={4} style={{width: "100%"}} key={index}>
          <Section sectionHeading={tile.tile_title}>
            <img width="100%" style={{minHeight: 200}} src={tile.tile_image ? tile.tile_image.url : ""}/>
          </Section>
        </Grid>
      );
    });
    return (
      <div style={{marginBottom: 24}}>
        <ContentContainer>
          <Grid container direction="row" spacing={24} justify="center" alignItems="center">
            {tileComponents}
          </Grid>
        </ContentContainer>
      </div>
    );
  }

  renderRemainingRows = () => {
    const computed_style = {margin: this.props.metadata.component_rows.length > 1 ? "-125px auto auto" : "auto", position: "relative", zIndex: 1}
    let root_this = this;
    let metadata = this.props.metadata;
    return(
      <div style={computed_style}>
          {
            this.props.metadata.component_rows.slice(1).map((row) => (
              <ContentContainer>
                <div style={{padding: 12}}>
                  <Grid container spacing={24} justify="center">
                    <Grid item sm={12} md={row.width || 6} >
                      {row.component1 ? React.createElement(root_this.mapper[row.component1], {metadata: metadata, scrollHeight: this.height_mapper(row.height)}) : null}
                    </Grid>
                    <Grid item sm={12} md={row.width || 6}>
                      {row.component2 ? React.createElement(root_this.mapper[row.component2], {metadata: metadata, scrollHeight: this.height_mapper(row.height)}) : null}
                    </Grid>
                  </Grid>
                </div>
              </ContentContainer>
            ))
          }
      </div>
    )
  }

  mapper = {
    "Newsletter": NewsLetter,
    "Announcements": Announcements,
    "Alumni in news": AlumniNews,
    "Twitter feed": TwitterFeed,
    "Recent memories": RecentMemories,
    "Upcoming events": UpcomingEvents,
    "Distinguished Alumni": DistinguishedAlumni,
  }

  height_mapper = (h) => {
    if(!h) return 468*2
    else return h.split('x')[0]*468
  }

  render() {
    const {metadata, classes} = this.props;
    var favicon = "";
    let root_this = this;
    if(metadata.favicon){
      favicon = metadata.favicon.url;
    }
    return (
     <React.Fragment>
       <Helmet>
         <title>{metadata.page_title}</title>
         <link rel="shortcut icon" href={favicon} />
       </Helmet>

       <Header metadata={metadata} />
       
      <div className={classes.mainComponentsOuterContainer}>
        
        <div style={{marginTop: 24}}>
          <Banners metadata={metadata} />
        </div>

        {this.renderVideos()}

        <ContentContainer style={{paddingBottom: 16}}><NetworkAndLogin metadata={metadata} /></ContentContainer>
        {/* <div className={classes.Pullup}>
        </div> */}
        {
          this.props.metadata.component_rows[0] ? (
            <ContentContainer style={{margin: "auto", position: "relative", zIndex: 1}}>
              <div style={{padding: 12}}>
                <Grid container spacing={24} justify="center">
                  <Grid item sm={12} md={this.props.metadata.component_rows[0].width || 6}>
                    {
                      this.props.metadata.component_rows[0].component1 ?
                          React.createElement(this.mapper[this.props.metadata.component_rows[0].component1], {metadata: metadata, scrollHeight: this.height_mapper(this.props.metadata.component_rows[0].height)}) :
                          null
                    }
                  </Grid>
                  <Grid item sm={12} md={this.props.metadata.component_rows[0].width || 6}>
                    {
                      this.props.metadata.component_rows[0].component2 ?
                          React.createElement(this.mapper[this.props.metadata.component_rows[0].component2], {metadata: metadata, scrollHeight: this.height_mapper(this.props.metadata.component_rows[0].height)}) :
                          null
                    }
                  </Grid>
                </Grid>
              </div>
            </ContentContainer>
          ):(
            <Announcements metadata={metadata} style={{marginTop: "-93px"}}/>
          )
        }
        {React.createElement(this.mapper["Newsletter"], {metadata: metadata})}
        {this.renderTiles()}
        <AlumniMap metadata={metadata} style={{margin: '32px auto'}}></AlumniMap>

        {
          (this.props.metadata.search_bar_enabled || this.props.metadata.component_rows.length > 1) ?
            (
              <div className={classes.Pullup}></div>
            ): null
        }
        

        <ContentContainer style={{margin: "-150px auto 150px", position: "relative", zIndex: 10}}>
          {
            this.props.metadata.search_bar_enabled ? (
              <div style={{position: "relative", margin: 12}}>
                <SearchBarComponent metadata={metadata}/>
              </div>
            ) : null
          }          
        </ContentContainer>
        {this.renderRemainingRows()}
      </div>
      <div style={{background: '#fff'}}>
        <div>
          {metadata.get_in_touch_enabled ? (<GetInTouch getInTouch={metadata.get_in_touch}/>) : null}
          {this.renderFooter()}
        </div>
      </div>
     </React.Fragment>
   );
  }
}


export default withStyles(styles)(Root);
